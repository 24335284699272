import React, {Component} from 'react';
import {
    Col,
    Row,
    Spin,
} from 'antd';
import {toast} from 'react-toastify';

import BasicLayout from '../common/BasicLayout';
import FilterPickerRow from '../common/FilterPickerRow';
import StatsCard from '../common/StatsCard';
import MerchantAPI from '../../utils/MerchantAPI';
import FilterInfoManager from '../../utils/FilterInfoManager';
import {VIEW_MODE} from '../../utils/Consts';
import FailedOrderChart from './FailedOrderChart';
import OrderPeriodChart from './OrderPeriodChart';
import EmailRateChart from './EmailRateChart';
import Utils from '../../utils/Utils';
import SummaryExportOrderCsv from "../summary/SummaryExportOrderCsv";

class OrdersView extends Component {
    constructor(props) {
        super(props);
        const cachedFilter = FilterInfoManager.loadCachedFilter();
        this.state = {
            loadingOrderStats: false,
            endTime: cachedFilter.endTime,
            startTime: cachedFilter.startTime,
            merchantId: cachedFilter.merchantId || null,
        }
    }

    componentDidMount() {
        this.loadOrderStats();
    }

    loadOrderStats = () => {
        let {merchantId, startTime, endTime} = this.state || {};
        const {mode} = this.props;
        if (!merchantId && mode === VIEW_MODE.ADMIN) {
            this.setState({
                loadingOrderStats: false,
            });
            return;
        }

        this.setState({
            loadingOrderStats: true,
        });
        // we need to convert the date range to UTC time, since our data is in UTC time
        // get the timezoneOffset from UTC in milliseconds
        const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
        startTime -= timezoneOffset;
        endTime -= timezoneOffset;

        if (mode === VIEW_MODE.ADMIN) {
            MerchantAPI.getAdmimOrders({merchantId, startTime, endTime}).then((response) => {
                this.setState({
                    loadingOrderStats: false,
                    orders: response.data,
                });
            })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        loadingOrderStats: false,
                    });
                    toast.error('Load failed. ' + error.toString());
                });
        } else {
            MerchantAPI.getOrders({startTime, endTime}).then((response) => {
                this.setState({
                    loadingOrderStats: false,
                    orders: response.data,
                });
            })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        loadingOrderStats: false,
                    });
                    toast.error('Load failed. ' + error.toString());
                });
        }
    }

    handleMerchantSelectorChanged = (merchantId) => {
        // reset orders
        const orders = null;
        this.setState({
            merchantId,
            orders,
        }, () => {
            this.loadOrderStats();
        });
    }

    handleDateRangeChanged = (dataRange) => {
        // reset orders
        const orders = null;
        const {startTime, endTime} = dataRange || {};
        this.setState({
            startTime,
            endTime,
            orders,
        }, () => {
            this.loadOrderStats();
        })
    }

    generateStatsData = (total) => {
        if (!total || Object.keys(total).length === 0) {
            // return empty if total is null
            return [];
        }

        let firstRow = [
            {
                label: 'Total Orders',
                value: Utils.formatIntNumber(total.totalOrders || 0),
                tooltip: 'Total number of orders',
            },
            {
                label: 'Failed Orders',
                value: Utils.formatIntNumber(total.failedOrders || 0),
                tooltip: 'Number of failed orders',
            }
        ];

        let failedRate = 0;
        if (total.totalOrders && total.failedOrders) {
            failedRate = total.failedOrders / total.totalOrders;
        }
        firstRow.push(
            {
                label: 'Order Failure Rate',
                value: Utils.formatPercentageNumber(failedRate),
                tooltip: 'Number of failed orders / Total number of orders',
            }
        );

        const cartToPurchaseRate = total.cartToPurchaseRate || 0;
        const returnRate = total.returnRate || 0;
        const processingPeriod = (total.processingPeriod || 0) / 86400000;
        const shippingPeriod = (total.shippingPeriod || 0) / 86400000;
        const secondRow = [
            {
                label: 'Add-to-cart To Purchase Conversion',
                value: Utils.formatPercentageNumber(cartToPurchaseRate),
                tooltip: 'Number of items added to shopping cart / Number of items purchased',
            },
            {
                label: 'Return Rate',
                value: Utils.formatPercentageNumber(returnRate),
                tooltip: 'Number of returned orders / Total number of orders',
            },
            {
                label: 'Processing Period',
                value: Utils.formatFloatNumber(processingPeriod) + ' days',
                tooltip: 'Time period from when the order was placed to when the order is shipped by the merchant',
            },
            {
                label: 'Shipping Period',
                value: Utils.formatFloatNumber(shippingPeriod) + 'days',
                tooltip: 'Time period from when the merchant ships out the order to domestic delivery',
            }
        ];

        const thirdRow = [
            {
                label: 'Orders Processing Over 7 Days',
                value: Utils.formatFloatNumber(total.awaitShippingOrders) || 0,
                tooltip: 'The number of orders that had a processing period over 7 days',
            },
            {
                label: 'Orders Not Receiving Confirmation Email Within 5 Days',
                value: Utils.formatPercentageNumber(total.noEmailIn5DayRate || 0),
                tooltip: 'Number of orders where a confirmation email was not received within 5 days',
            }
        ]

        const stats = [
            {
                cards: firstRow,
                grid: {
                    xs: 20,
                    sm: 6,
                    md: 6,
                    lg: 6,
                    xl: 6,
                }
            },
            {
                cards: secondRow,
                grid: {
                    xs: 20,
                    sm: 5,
                    md: 5,
                    lg: 4,
                    xl: 4,
                }
            },
            {
                cards: thirdRow,
                grid: {
                    xs: 20,
                    sm: 8,
                    md: 8,
                    lg: 8,
                    xl: 8,
                }
            },
        ];

        return stats;
    }

    render() {
        const {
            loadingOrderStats,
            merchantId,
            startTime,
            endTime,
            orders,
        } = this.state || {};

        let dynamicContent;
        if (loadingOrderStats) {
            dynamicContent = (
                <Row gutter={8} type="flex" justify="center" key="spin">
                    <Spin size="large"/>
                </Row>
            )
        } else {
            const {
                total,
                daily,
            } = orders || {};

            const cardsStats = this.generateStatsData(total);
            const cardRows = cardsStats.map((row, rowIndex) => {
                const grid = row.grid;
                const cols = row.cards.map((col) => {
                    return (
                        <Col xs={grid.xs} sm={grid.sm} md={grid.md} lg={grid.lg} xl={grid.xl} key={col.label}>
                            <StatsCard data={col}/>
                        </Col>
                    )
                })
                return (
                    <Row gutter={8} type="flex" justify="center" key={"cards" + rowIndex}>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}/>
                        {cols}
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}/>
                    </Row>
                )
            })
            const processingChartParameters = [
                {
                    key: 'merchantProcessingPeriod',
                    label: 'Time to Ship',
                },
                {
                    key: 'merchantShippingPeriod',
                    label: 'Time to Warehouse',
                },
            ];

            dynamicContent = (
                <React.Fragment>
                    {cardRows}
                    <br/>
                    <Row gutter={8} type="flex" justify="center" key="failedOrders">
                        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                            <FailedOrderChart value={daily}
                                              total={total}
                                              startTime={startTime}
                                              endTime={endTime}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={8} type="flex" justify="center" key="processing-period">
                        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                            <OrderPeriodChart value={daily}
                                              startTime={startTime}
                                              endTime={endTime}
                                              parameters={processingChartParameters}
                                              title={"Processing and Shipping Periods"}
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row gutter={8} type="flex" justify="center" key="email">
                        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                            <EmailRateChart value={daily}
                                            startTime={startTime}
                                            endTime={endTime}/>
                        </Col>
                    </Row>
                    <br/>
                </React.Fragment>
            )
        }

        const content = (
            <div>
                <div style={{float: 'left', padding: '24px'}}>
                    <SummaryExportOrderCsv startTime={startTime}
                                           endTime={endTime}
                                           merchantId={merchantId}
                    />
                </div>
                <div style={{padding: '24px 0', background: '#fff', textAlign: 'center'}}>
                    <FilterPickerRow startTime={startTime}
                                     endTime={endTime}
                                     merchantId={merchantId}
                                     onDateRangeChanged={this.handleDateRangeChanged}
                                     onMerchantChanged={this.handleMerchantSelectorChanged}
                    />
                    <br/>
                    {dynamicContent}
                </div>
            </div>

        )
        return (
            <BasicLayout content={content}/>
        );
    }
}

export default OrdersView;