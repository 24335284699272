import React, {Component} from 'react';
import {Icon} from 'antd';
import MerchantAPI from "../../utils/MerchantAPI";
import fileDownload from "../common/file-download"
import moment from 'moment';

class SummaryExportOrderCsv extends Component {

    downloadCsv = ({merchantId, startTime, endTime}) => {
        const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
        startTime -= timezoneOffset;
        endTime -= timezoneOffset;
        MerchantAPI.downloadOrdersCsv({merchantId, startTime, endTime}).then((resp) => {
            const fileName = moment(startTime).format('YYYY-MM-DD') + '_' + moment(endTime).format('YYYY-MM-DD') + '_order_details.csv';
            fileDownload(resp.data, fileName);
        })
    };

    render() {
        const {startTime, endTime, merchantId} = this.props || {};
        return (
            <div onClick={() => this.downloadCsv({merchantId, startTime, endTime})}>
                <Icon type="cloud-download"
                      style={{fontSize: '20px', color: '#08c'}}>
                </Icon>
                <span style={{marginLeft: '10px', fontWeight: 'bold'}}>
                          Download successful order details
                </span>
            </div>
        );
    }
}

export default SummaryExportOrderCsv;
